body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #20232a;
  min-height: 100vh;
  color: white;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  font-family: "Roboto", sans-serif;
}

/* Pas fou ca mais bon */
[data-sonner-toast] [data-button] {
  font-family: "Roboto", sans-serif;
  background-color: transparent !important;
  border: none !important;
  color: hsl(358, 100%, 81%) !important;
}
